import React from 'react'
import styles from './index.module.scss'
import ReactTooltip from 'react-tooltip'

import duefocusLogo from '../../img/icons/Logo_icon_duefocus.svg'

import icon_green from '../../img/icons/Mark_positive.svg'
import icon_red from '../../img/icons/Icon_s_arrow-down_gray.svg'
import info from '../../img/icons/Mark_question.svg'
import improve from '../../img/icons/improve.png'

const tooltip1 = 'Sign up and log in to DueFocus via integration: <br> - Google; <br> - Trello; <br> - Gitlab; <br> - Github.';
const tooltip2 = 'Connect to DueFocus by several methods:<br>  - login/pass or token;<br> - OAuth or login/pass;<br>- OAuth or token.';
const tooltip3 = 'Unlike some time-tracking system competitors,<br> DueFocus allows you to quickly create tasks/issues directly <br> into integration project in real-time mode through <br>the desktop application, eliminating the need to switch<br> to the integration page.';
const tooltip4 = 'Presence of description field for tasks and projects';
const tooltip5 = 'Some integrations have embedded timer button to start time tracking on task page';
const tooltip6 = "Analyze user's productivity during the work on tasks";
const tooltip7 = 'Assign tasks for several members';
const tooltip8 = 'Change tasks status in integration <br>(Backlog, In Progress, Done etc)';
const tooltip9 = 'Presence of task estimation (total planned time) in integrations';
const tooltip10 = 'Analyze if the employee follows the tasks estimate or exceeds it';
const tooltip11 = 'Report spent time into tasks';
const tooltip12 = 'Input spent time manually';
const tooltip13 = 'DueFocus content button appears for a prompt <br> commencement of time tracking directly <br> from task page (if Chrome Extension is installed)';
const tooltip14 = 'Make screenshots during time tracking and save <br> them to your personal storage (GDrive or Dropbox)';
const tooltip15 = "Check timesheets in system";
const tooltip16 = 'Save your latest reports ';
const tooltip17 = 'Observe reports and statistics of all users involved into a project';
const tooltip18 = 'Invite team members into a project ';
const tooltip19 = 'Create a team with project members <br>  and monitor their activity and productivity';
const tooltip20 = 'Create invoices, send them to clients and record all payments';
const tooltip21 = 'Launch time tracking in one-click <br>  and reassign tracked time to any task later';
const tooltip22 = 'Leave comments in tasks';


export default class FirstBlock extends React.Component {
  render() {
    return (
      <>
        <div className={styles.tableMain}>
          <h3>DueFocus Features Support</h3>
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <ReactTooltip />
          <div className={styles.table}>
            <table>
              <thead>
                <tr>
                  <td>Features</td>
                  <td><img src={this.props.logo} width="auto" height="auto" alt='img' /></td>
                  <td><img src={duefocusLogo} width="auto" height="auto" alt='img' /></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><img data-html="true" data-tip={tooltip1} src={info} width="auto" height="auto" alt="info" />Advanced Registration</td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip2} src={info} width="auto" height="auto" alt="info" />Adaptive Syncing</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip3} src={info} width="auto" height="auto" alt="info" />Embedded Task Creation</td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip4} src={info} width="auto" height="auto" alt="info" />Task & Project Description</td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip5} src={info} width="auto" height="auto" alt="info" />Instant Tracking</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip6} src={info} width="auto" height="auto" alt="info" />Productivity Counting</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><div className={styles.improve}><img src={icon_green} width="auto" height="auto" alt='img' /><img className={styles.hide} src={improve} width="auto" height="auto" alt='improve' /></div></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip7} src={info} width="auto" height="auto" alt="info" />Multiple Assignment</td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip8} src={info} width="auto" height="auto" alt="info" />Task Stage Mapping</td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                  <td><div className={styles.improve}><img src={icon_green} width="auto" height="auto" alt='img' /><img className={styles.hide} src={improve} width="auto" height="auto" alt='improve' /></div></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip9} src={info} width="auto" height="auto" alt="info" />Progressive Planning</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip10} src={info} width="auto" height="auto" alt="info" />Planning Hit Analysis</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><div className={styles.improve}><img src={icon_green} width="auto" height="auto" alt='img' /><img className={styles.hide} src={improve} width="auto" height="auto" alt='improve' /></div></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip11} src={info} width="auto" height="auto" alt="info" />Spent Time Accounting</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><div className={styles.improve}><img src={icon_green} width="auto" height="auto" alt='img' /><img className={styles.hide} src={improve} width="auto" height="auto" alt='improve' /></div></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip12} src={info} width="auto" height="auto" alt="info" />Manual Reporting</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><div className={styles.improve}><img src={icon_green} width="auto" height="auto" alt='img' /><img className={styles.hide} src={improve} width="auto" height="auto" alt='improve' /></div></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip13} src={info} width="auto" height="auto" alt="info" />Quick Start Button</td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip14} src={info} width="auto" height="auto" alt="info" />Secure Screenshots</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><div className={styles.improve}><img src={icon_green} width="auto" height="auto" alt='img' /><img className={styles.hide} src={improve} width="auto" height="auto" alt='improve' /></div></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip15} src={info} width="auto" height="auto" alt="info" />User Timesheets</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><div className={styles.improve}><img src={icon_green} width="auto" height="auto" alt='img' /><img className={styles.hide} src={improve} width="auto" height="auto" alt='improve' /></div></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip16} src={info} width="auto" height="auto" alt="info" />Store Reports</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><div className={styles.improve}><img src={icon_green} width="auto" height="auto" alt='img' /><img className={styles.hide} src={improve} width="auto" height="auto" alt='improve' /></div></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip17} src={info} width="auto" height="auto" alt="info" />Team Statistics</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><div className={styles.improve}><img src={icon_green} width="auto" height="auto" alt='img' /><img className={styles.hide} src={improve} width="auto" height="auto" alt='improve' /></div></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip18} src={info} width="auto" height="auto" alt="info" />Inviting to Project</td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip19} src={info} width="auto" height="auto" alt="info" />Team Pulse</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><div className={styles.improve}><img src={icon_green} width="auto" height="auto" alt='img' /><img className={styles.hide} src={improve} width="auto" height="auto" alt='improve' /></div></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip20} src={info} width="auto" height="auto" alt="info" />Invoices</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><div className={styles.improve}><img src={icon_green} width="auto" height="auto" alt='img' /><img className={styles.hide} src={improve} width="auto" height="auto" alt='improve' /></div></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip21} src={info} width="auto" height="auto" alt="info" />Cold Start Mode</td>
                  <td><img src={icon_red} width="auto" height="auto" alt='img' /></td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                </tr>
                <tr>
                  <td><img data-html="true" data-tip={tooltip22} src={info} width="auto" height="auto" alt="info" />Task Reports</td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                  <td><img src={icon_green} width="auto" height="auto" alt='img' /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}
